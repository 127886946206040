<template>
    <v-row class="caption-1" no-gutters ref="child" style="min-height: 440px; height: 100%">
        <v-col cols="12" class="py-4 pr-4 pl-3">
            <v-row no-gutters align="center">
                <v-col cols="auto" class="pr-4">
                    <v-icon size="18">mdi-comment-multiple</v-icon>
                </v-col>
                <v-col>
                    <div class="sub-title-2 font-weight-bold">{{ $tc('global.name.comment', 2) }}</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col
                v-if="messages.length > 0 && ready"
                cols="12"
                :style="readOnly ? {height: 'calc(100% - 53px)'}:{height: 'calc(100% - 102px)'}"
        >
            <v-row align="end" no-gutters class="fill-height">
                <div class="px-2 d-flex justify-start fill-height" style="width: 100%; flex-direction: column-reverse; overflow-y: auto; overflow-x: hidden;" align="end" id="scroll-target2">
                    <v-hover v-for="(message, i) in messages" :key="i" v-slot:default="{ hover }">
                        <div>
                            <v-row v-if="message.contact !== null && message.contact.id !== $store.getters['auth/contact_id']" align="center" no-gutters class="pb-3">
                                <div style="max-width: 75%" class="text-left">
                                    <div class="grey darken-2 pa-3 elevation-2 d-inline-flex rounded-t-lg rounded-br-lg paragraph" style="color: white; text-align: left; word-break: break-word;">
                                        {{ message.message }}
                                    </div>
                                    <div class="mt-1">{{ message.contact.display_name }} &mdash; {{ message.created | toLocalDate  }}</div>
                                </div>
                            </v-row>
                            <v-row class="pb-3" justify="end" no-gutters v-else ref="parent">
                                <v-col style="text-align: right" cols="auto">
                                    <v-row no-gutters class="flex-nowrap">
                                        <v-col cols="auto" class="pr-1" style="min-width: 60px">
                                            <v-fade-transition>
                                                <v-btn icon small v-show="hover || edition === message.id" @click="item2delete = {item: message, index: i}; dialog = true" v-if="destroy" :loading="loading">
                                                    <v-icon size="18">mdi-delete</v-icon>
                                                </v-btn>
                                            </v-fade-transition>
                                            <v-fade-transition>
                                                <v-btn icon small v-show="hover || edition === message.id" @click="edition = message.id; parent_width = $refs.parent[i].clientWidth; new_message = messages[i].message" v-if="edition !== message.id && update" :loading="loading">
                                                    <v-icon size="18">mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn icon small v-show="hover || edition === message.id" @click="edition = null" v-else-if="update" :loading="loading">
                                                    <v-icon size="18">mdi-close</v-icon>
                                                </v-btn>
                                            </v-fade-transition>
                                        </v-col>
                                        <v-spacer />
                                        <div :style="edition !== message.id ? {}:{display: 'none !important'}" class="primary pa-3 elevation-2 d-inline-flex white--text rounded-t-lg rounded-bl-lg paragraph" style="text-align: left; word-break: break-word;">
                                            {{ message.message }}
                                        </div>
                                        <TextField v-show="edition === message.id" v-model="new_message" @enter="saveEdition()" :styles="{width: parent_width - 60 + 'px'}" autofocus />
                                    </v-row>
                                    <div class="mt-1">{{ message.created | toLocalDate }}</div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-hover>
                </div>
            </v-row>
        </v-col>

        <v-col v-else-if="!ready">
            <div class="pa-8 text-center">
                <v-progress-circular indeterminate size="40" color="primary"/>
            </div>
        </v-col>

        <v-col v-else cols="12" style="overflow-y: auto; overflow-x: hidden; align-items: center; justify-content: center; display: flex; flex-direction: column" :style="readOnly ? {height: 'calc(100% - 53px)'}:{height: 'calc(100% - 100px)'}">
            <div class="sub-title-1 grey--text mb-4" align="center" >{{ $tc('single_trad.Comments.no_comments', 1) }}</div>
            <v-icon color="grey" large>mdi-folder-information</v-icon>
        </v-col>

        <v-col cols="12" v-if="!readOnly && ready">
            <v-divider />
        </v-col>
        <v-col cols="12" v-if="!readOnly && ready">
            <v-row align="center" no-gutters class="flex-nowrap">
                <v-menu top offset-y :close-on-content-click="false" :nudge-left="50" v-if="channels" rounded="lg">
                    <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip :top="$vuetify.breakpoint.width >= 600" :bottom="$vuetify.breakpoint.width < 600">
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn v-on="{ ...onMenu, ...onTooltip }" color="secondary" fab depressed height="30" x-small class="ml-2">
                                    <v-icon size="20">mdi-chat-alert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $tc('single_trad.Comments.choose_channel', 1) }}</span>
                        </v-tooltip>
                    </template>
                    <v-list class="py-0">
                        <v-list-item @click="selectChannel(channel)" v-for="(channel, ch) in channels" :key="ch" :class="selected_channel.value === channel.value ? 'secondary elevation-2':''" link>
                            <v-list-item-content :class="selected_channel.value === channel.value ? 'white--text':''">
                                <div class="paragraph">{{ channel.display_name }}</div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <TextField v-model="message_text" :placeholder="$tc('single_trad.Comments.enter_message', 1)" @enter="(e) => sendMessage()" class="ml-2 my-2" />

                <v-btn x-small @click="sendMessage()" :text="message_text === '' || message_text === null || message_text.trim() === ''" :loading="loading" color="transparent" fab depressed class="mx-2" :disabled="message_text === '' || message_text === null || message_text.trim() === ''">
                    <v-icon size="20" color="primary">mdi-send</v-icon>
                </v-btn>
            </v-row>
        </v-col>

        <Dialog
                type="confirm"
                v-model="dialog"
                :message="$t('single_trad.Comments.dialog')"
                @confirm="deleteMessage()"
                @cancel="dialog = false"
                @close="dialog = false"
                :loading="loading"
        />
    </v-row>
</template>
<script>
    export default {
        name: "Comments",
        props: ['path', 'readOnly', 'edit', 'update', 'destroy', 'pevents'],
        components: {
            TextField: () => import("@/components/fields/TextField.vue"),
            Dialog: () => import("@/components/ui/Dialog.vue"),
            FieldGroup: () => import("@/components/FieldGroup.vue")
        },
        data(){
            return {
                dialog: false,
                edition: null,
                new_message: '',
                messages: [],
                message_text: '',
                channels: null,
                selected_channel: {display_name: 'Publique', value: 'all'},
                included_fields:["action", "created"],
                create_permission: true,
                loading: false,
                ready: false,
                parent_width: 0,
                item2delete: null
            }
        },
        methods:{
            deleteMessage(){
                this.loading = true

                this.$wsc.deleteItem(this.path + "/comment", this.item2delete.item.id, success => {
                    this.messages.splice(this.item2delete.index, 1)
                    this.item2delete = null
                    this.dialog = false
                    this.loading = false
                }, fail => {
                    this.loading = false
                })
            },
            saveEdition(){
                this.loading = true

                let index = this.messages.findIndex(e => e.id === this.edition)
                let item = this.messages[index]

                this.$wsc.patchItem(this.path + "/comment", item.id, {message: this.new_message}, success => {
                    this.messages[index] = success
                    this.edition = null
                    this.loading = false
                }, fail => {})
            },
            selectChannel(channel){
                if(channel !== this.selected_channel){
                    this.selected_channel = channel
                    this.fetchComments()
                }
            },
            fetchEntityFields(){
                this.$wsc.getOptions(this.$route.params, {}, this.path + "/comment", success => {
                    this.channels = success.fields.channel.choices
                    this.selected_channel = this.channels[0]
                }, fail => {

                })
            },
            fetchComments(){
                this.ready = false

                this.$wsc.getList(this.path + "/comment", {channel: this.selected_channel.value, ordering: '-created'}, messages => {
                    this.messages = messages
                    this.scrollDown()
                    this.ready = true
                    this.$emit('loaded', this.$refs.child)
                }, fail => {

                })
            },
            scrollDown () {
                this.$nextTick(() => {
                    if(document.getElementById('scroll-target2')){
                        const container = document.getElementById('scroll-target2')
                        container.scrollTop = container.scrollHeight
                    }
                })
            },
            sendMessage () {
                if (this.message_text !== '' && this.message_text !== null && this.message_text.trim() !== '') {
                    this.loading = true
                    this.$wsc.createItem(this.path + "/comment", {
                        message: this.message_text,
                        channel: this.selected_channel.value,
                        contact: this.$store.getters['auth/contact_id']

                    }, message => {
                        this.messages.unshift(message)
                        this.message_text = null
                        this.scrollDown()
                        this.loading = false
                        this.$store.dispatch('snackbar/success', this.$t('snackbar.Comments.sendMessage.success'))
                    }, (error) => {
                        this.loading = false
                    })
                } else {
                    this.scrollDown()
                }
            },
            setUpHooks(){
                this.pevents.wait('update', () => {
                    this.fetchComments()
                })
            }
        },
        created(){
            this.setUpHooks()
            this.fetchEntityFields()
            this.fetchComments()
        }
    }
</script>
